import { graphql } from 'gatsby';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout } from '../../components/layout/layout';
import { textOptions } from '../../util/rich-text';
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from '../../components/cards/author-card';
import { PageHeading } from '../../components/layout/page-heading';
import FootprintsLayout from '../../components/layout/footprints-layout';

const Address = ({ data }) => {
  const address = data.contentfulAddress;
  return (
    <Layout title={address.title}>
      <FootprintsLayout>
        <div className="flex mt-6">
          <DesktopAuthorCard author={address.author} />
          <main className="max-w-screen-md md:px-4 md:ml-8">
            <PageHeading title={address.title} />
            <MobileAuthorCard author={address.author} />
            <div className="mt-6">
              {address.content && renderRichText(address.content, textOptions)}
            </div>
          </main>
        </div>
      </FootprintsLayout>
    </Layout>
  );
};

export default Address;

export const query = graphql`
  query ($slug: String!) {
    contentfulAddress(slug: { eq: $slug }) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            description
            fixed(width: 1200) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
